<template>
  <SetupDialogSlot header="Company Setup" @close="onClose">
    <template #content>
      <Certificate @next="onClose"/>
    </template>
  </SetupDialogSlot>
</template>
<script>
import { defineComponent } from 'vue';

import SetupDialogSlot from '@/core/components/slots/SetupDialogSlot.vue';
import Certificate from '@/modules/account-setup/on-boarding/Certificate.vue';

export default defineComponent({
  components: {
    Certificate,
    SetupDialogSlot
  },

  emits: ['close'],

  data() {
    return {};
  },

  computed: {
  },

  methods: {
    onComplete() {
    },

    onClose() {
      this.$emit('close');
    }
  },
});
</script>
<style lang="scss" scoped>
</style>
