<template>
    <InfoCardSlot
      class="passport-value"
      title="Your Value"
      iconName="specialisms-dark.svg"
      customPath="icons/trade-passport-icons"
      actionLabel="Explore Your Value">
      <template #custom-content>
        <div class="passport-value__content h-100 w-100 flex-row ai-c jc-sb">
          <!-- NOTE: Create reusable Item for this  -->
          <div class="flex-column gap-0 text-center">
            <Typography variant="span" textColor="rgba(12, 15, 74, 0.5)">£310</Typography>
            <Typography
              variant="custom"
              textSize="10px"
              textWeight="600"
              textColor="rgba(12, 15, 74, 0.5)">
              Lowest
            </Typography>
          </div>
          <el-divider/>
          <!-- NOTE: Create reusable Item for this  -->
          <div class="relative">
            <div class="passport-value__value flex-column gap-0 text-center absolute">
                <Typography variant="h5" textColor="#4B4BFF">£340</Typography>
                <Typography
                  variant="custom"
                  textSize="10px"
                  lineHeight="14px"
                  textWeight="600">
                  Estimated<br> Day Rate
                </Typography>
            </div>
            <Icon
              customPath="icons/trade-passport-icons"
              iconName="ellipse-purple-2.png"
              iconHeight="150px"
              iconWidth="150px"/>
          </div>
          <el-divider/>
          <!-- NOTE: Create reusable Item for this  -->
          <div class="flex-column gap-0 text-center">
            <Typography variant="span" textColor="rgba(12, 15, 74, 0.5)">£390</Typography>
            <Typography
              variant="custom"
              textSize="10px"
              textWeight="600"
              textColor="rgba(12, 15, 74, 0.5)">
              Lowest
            </Typography>
          </div>
        </div>
      </template>
    </InfoCardSlot>
</template>
<script>
import { defineComponent } from 'vue';

import InfoCardSlot from '@/core/components/slots/InfoCardSlot.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    InfoCardSlot,
    Typography,
    Icon
  }
});
</script>
<style lang="scss" scoped>
.passport-value {
  &__content {
    padding: 1rem 2.5rem;
    box-sizing: border-box;
  }

  &__value {
    top: 35%;
    left: 0;
    right: 0;
  }
}
</style>
