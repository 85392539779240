<template>
    <InfoCardSlot
      class="passport-projects"
      title="Your Completed projects"
      iconName="trades-icon.svg"
      customPath="icons/trade-passport-icons"
      actionLabel="Manage Projects"
      :isEmpty="!completedProjects.length"
      emptyStateLabel="No completed Projects available"
      @handle-event-click="onManageJobs">
      <template #custom-content>
        <div class="passport-projects__content flex-column jc-fs gap-1 h-100 w-100">
          <div class="passport-projects__wrapper grid gap-1 w-100">
            <ProjectItem v-for="(item, index) in completedProjects" :key="index"/>
          </div>
          <Typography variant="custom" textSize="0.75rem" textWeight="400">+ 34 More Projects</Typography>
        </div>
      </template>
    </InfoCardSlot>
</template>
<script>
import { defineComponent } from 'vue';

import ProjectItem from '@/core/components/common/item/ProjectItem.vue';
import InfoCardSlot from '@/core/components/slots/InfoCardSlot.vue';
import Typography from '@/core/components/ui/Typography.vue';
import { MANAGE_JOB_ROUTE } from '@/modules/manage-job/routes';

export default defineComponent({
  components: {
    InfoCardSlot,
    Typography,
    ProjectItem
  },

  data() {
    return {
      completedProjects: []
    };
  },

  methods: {
    onManageJobs() {
      this.$router.push({ name: MANAGE_JOB_ROUTE });
    }
  },
});
</script>
<style lang="scss" scoped>
.passport-projects {
  &__wrapper.grid {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    max-height: 200px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
