<template>
    <div>
      <InfoCardSlot
        class="passport-accreditations"
        title="Your Accreditations"
        iconName="trades-icon.svg"
        customPath="icons/trade-passport-icons"
        actionLabel="Manage Accreditations"
        @handle-event-click="setCertificationsModal(true)">
        <template #custom-content>
          <div class="passport-accreditations__content flex-column jc-fs gap-1 h-auto w-100">
            <div class="passport-accreditations__wrapper grid gap-1 w-100">
              <AccreditationItem
                :userCertificate="userCertificate"
                v-for="(userCertificate, index) in userCertificates" :key="index"/>
            </div>
          </div>
        </template>
     </InfoCardSlot>
      <!-- MODAL -->
      <CertificationsModal :show="certificationsModal" @close="setCertificationsModal(false)"/>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import AccreditationItem from '@/core/components/common/item/AccreditationItem.vue';
import CertificationsModal from '@/core/components/common/modals/new-designs/CertificationsModal.vue';
import InfoCardSlot from '@/core/components/slots/InfoCardSlot.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    InfoCardSlot,
    AccreditationItem,
    CertificationsModal,
  },

  data() {
    return {
      certificationsModal: false
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user', 'userCertificates', 'certificateVerificationState']),
  },

  async created() {
    await this.getUserCertificates(this.user.id);
  },

  methods: {
    ...mapActions(USERS_STORE, [
      'getUserCertificates',
    ]),

    setCertificationsModal(state) {
      this.certificationsModal = state;
    }
  },
});
</script>
<style lang="scss" scoped>
.passport-accreditations {
  &__wrapper.grid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    max-height: 200px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
