<template>
    <div>
      <InfoCardSlot
        class="passport-specialism"
        title="Your Trade Specialisms"
        iconName="specialisms-dark.svg"
        customPath="icons/trade-passport-icons"
        actionLabel="Manage Specialisms"
        :isEmpty="!categoriesToDisplay.length"
        emptyStateLabel="No categories selected"
        :isLoading="isLoading"
        @handle-event-click="setSpecialismModal(true)">
        <template #custom-content>
          <div class="passport-specialism__content grid gap-1 h-100 w-100" v-if="!isLoading">
            <!-- NOTE: Create reusable Item for this  -->
            <div
              class="flex-column ai-c jc-fs gap-half"
              v-for="(category, index) in categoriesToDisplay" :key="index">
              <span class="passport-specialism__category-icon flex-row ai-c jc-c">
                <inline-svg
                  class="passport-specialism__img"
                  width="20px" height="20px"
                  :src="require(`@/assets/category-icons/${category?.iconName.concat('.svg')}`)"></inline-svg>
              </span>
              <Typography
                variant="p"
                textColor="#264FD5"
                lineHeight="18px"
                textWeight="400"
                textAlign="center">
                {{ category?.label }}
              </Typography>
            </div>
          </div>
        </template>
      </InfoCardSlot>
      <!-- MODAL -->
      <SpecialismModal :show="openSpecialismModal" @close="onCloseModal"/>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import SpecialismModal from '@/core/components/common/modals/new-designs/SpecialismModal.vue';
import InfoCardSlot from '@/core/components/slots/InfoCardSlot.vue';
import Typography from '@/core/components/ui/Typography.vue';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    InfoCardSlot,
    Typography,
    SpecialismModal,
  },

  data() {
    return {
      openSpecialismModal: false,
      userSkills: [],
      selectedCategories: [],
      selectedSubCategories: [],
      isLoading: false,
      categoriesToDisplay: []
    };
  },

  async created() {
    await this.getCategories();
    await this.onInitializeUserSkills();
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
    ...mapGetters(PROJECTS_STORE, [
      'categories',
    ]),

    availableCategories() {
      const { categories } = this;

      if (categories.length) {
        return this.transformCategoriesToOptions(categories);
      }

      return [];
    },
  },

  methods: {
    ...mapActions(PROJECTS_STORE, [
      'getCategories',
    ]),

    ...mapActions(USERS_STORE, [
      // skills
      'saveUserSkills',
      'getUserSkills',
    ]),

    async onInitializeUserSkills() {
      this.isLoading = true;
      this.userSkills = [];
      this.categoriesToDisplay = [];
      this.selectedSubCategories = [];
      this.selectedCategories = [];
      this.userSkills = await this.getUserSkills(this.user?.id);

      this.setSelectedCategoriesRecord(this.userSkills);
      this.getCategoriesToDisplay();
      this.isLoading = false;
    },

    setSpecialismModal(state) {
      this.openSpecialismModal = state;
    },

    onCloseModal(hasSavedNewData = false) {
      if (hasSavedNewData) {
        this.onInitializeUserSkills();
      }

      this.setSpecialismModal(false);
    },

    transformCategoriesToOptions(categories) {
      return categories.map((category) => {
        return {
          value: category.id,
          label: category.tpLabel,
          iconName: category.iconName,
          children: category.subCategories.map((sub) => {
            const modifiedLabel = sub.label.replace(/\n/g, '');
            return { value: sub.id, label: modifiedLabel };
          }),
        };
      });
    },

    setSelectedCategoriesRecord(userSkills) {
      const { selectedCategories, selectedSubCategories } = this;
      userSkills.forEach((item) => {
        const categoryId = item.subCategory.category.id;
        const subCategoryId = item.subCategory.id;
        // Check if the category ID is not already in the selectedCategories array
        if (!selectedCategories.includes(categoryId)) {
          selectedCategories.push(categoryId);
        }
        if (!selectedSubCategories.includes(subCategoryId)) {
          selectedSubCategories.push(subCategoryId);
        }
      });
    },

    getCategoriesToDisplay() {
      // Filter availableCategories based on selectedCategories
      this.categoriesToDisplay = this.availableCategories.filter((category) => {
        return this.selectedCategories.includes(category.value);
      });
    },
  },
});
</script>
<style lang="scss" scoped>
.passport-specialism {
//   min-width: 550px;
  &__content {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    max-height: 214px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__category-icon {
    width: 40px;
    height: 40px;
    min-width: 16px;
    background: #C5D2F2;
    border-radius: 100%;
    box-sizing: border-box;
  }

  &__img {
    :deep(path) {
      fill: #264FD5;
      fill-opacity: 1;
    }
  }
}
</style>
