<template>
    <InfoCardSlot
      title="Your Associated Companies"
      iconName="services.svg"
      customPath="icons/trade-passport-icons"
      actionLabel="Manage Companies"
      @handle-event-click="setAssociatedCompanyModal(true)">
      <template #custom-content>
        <div class="passport-associated-companies-content flex-column h-100 w-100">
          <!-- NOTE: Create reusable Item for this  -->
          <div
            class="passport-associated-companies-content__item flex-column gap-half"
            v-for="(company, index) in data" :key="index">
            <Typography variant="h6">{{ company.companyName }}</Typography>
            <Status :status="company.status"/>
            <Status :status="iknowaVerifiedStatus.IKNOWA_VERIFIED" v-if="company.isIknowaVerified"/>
          </div>

          <!-- MODAL -->
          <AssociationsModal :show="associatedCompanyModal" @close="setAssociatedCompanyModal(false)"/>
        </div>
      </template>
    </InfoCardSlot>
</template>
<script>
import { defineComponent } from 'vue';

import AssociationsModal from '@/core/components/common/modals/new-designs/AssociationsModal.vue';
import Status from '@/core/components/common/status/Status.vue';
import InfoCardSlot from '@/core/components/slots/InfoCardSlot.vue';
import Typography from '@/core/components/ui/Typography.vue';
import iknowaVerifiedStatus from '@/core/constants/status/iknowa-verified';
import statusType from '@/core/constants/status/type';

export default defineComponent({
  components: {
    InfoCardSlot,
    Typography,
    Status,
    AssociationsModal
  },

  data() {
    return {
      statusType,
      iknowaVerifiedStatus,
      data: [
        {
          status: {
            type: statusType.VERIFIED,
            statusName: 'VERIFIED',
            spanText: 'company association'
          },
          isIknowaVerified: true,
          companyName: 'Iknowa Ltd'
        },
        {
          status: {
            type: statusType.ALERT,
            statusName: 'PENDING',
            spanText: 'company confirmation'
          },
          isIknowaVerified: false,
          companyName: 'HelloCrossman Ltd'
        },
      ],

      associatedCompanyModal: false
    };
  },

  methods: {
    setAssociatedCompanyModal(state) {
      this.associatedCompanyModal = state;
    }
  },
});
</script>
<style lang="scss" scoped>
.passport-associated-companies-content {
  &__item {
    padding-bottom: 1rem;
    box-sizing: border-box;
    border-bottom: 1px solid #C5D2F2;

    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
