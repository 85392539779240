<template>
    <div class="accreditation-item flex-column ai-fs jc-fs gap-0">
        <Typography
            variant="p"
            textWeight="700">
            {{ userCertificate?.certificate?.name }}
        </Typography>
        <Typography
            variant="p"
            textWeight="400"
            textColor="rgba(12, 15, 74, 0.5)">
            {{ userCertificate?.metadata?.registrationNumber }}
        </Typography>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Typography
  },
  props: ['userCertificate'],
});
</script>
<style lang="scss" scoped>
.accreditation-item {
  box-sizing: border-box;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    height: 50%;
    border-right: 1px solid #C5D2F2;
  }

  &:last-child::after {
    border-right: none; // Remove border-right for the last child
  }
}
</style>
