<template>
    <div class="project-item flex-row ai-c gap-half">
      <ProjectItemThumbnail class="project-item__thumbnail" :project="item"/>
      <div class="flex-column gap-0">
        <Typography variant="p" lineHeight="14px">Light Studio Flat - Renovation</Typography>
        <div class="flex-row ai-c gap-half">
          <Icon
            customPath="profile"
            iconName="location-icon.svg"
            iconHeight="0.625rem"
            iconWidth="0.625rem"/>
          <Typography
            variant="custom"
            textSize="0.75rem"
            textWeight="400"
            textColor="rgba(12, 15, 74, .5)">
            W12 9RE
          </Typography>
        </div>
      </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import ProjectItemThumbnail from '@/modules/property-details-children/components/ProjectItemThumbnail.vue';

export default defineComponent({
  name: 'ProjectItem',

  components: {
    ProjectItemThumbnail,
    Typography,
    Icon
  }
});
</script>
<style lang="scss" scoped>
.project-item {
  &__thumbnail {
    min-width: 52px !important;
    min-height: 52px !important;
    max-width: 52px !important;
    max-height: 52px !important;
    height: 100% !important;
    width: 100% !important;
  }
}
</style>
