<template>
  <div class="passport-overview default-layout-desktop-padding">
    <div class="passport-overview__main-container flex-column h-100">
      <WelcomeBanner
        header="Welcome to your Iknowa Trades Passport"
        buttonLabel="Create a workstation"
        @handle-event="onHandlePrimaryButtonClick">
        <template #secondary-action>
          <Button
            v-if="!isVerified"
            buttonText="fully unlock your iknowa dashboard"
            :isActive="true"
            activeColor="#FFF"
            activeFontColor="#4F55F0"
            borderColor="#4F55F0"/>
        </template>
      </WelcomeBanner>
      <div class="passport-overview__content col-3 w-100 grid">
        <PassportValueCard/>
        <PassportSpecialismCard/>
        <PassportAssociatedCompanies/>
      </div>
      <div class="passport-overview__content col-2 w-100 grid">
        <PassportCompletedProjects/>
        <PassportAccreditations/>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import WelcomeBanner from '@/core/components/layouts/WelcomeBanner.vue';
import Button from '@/core/components/ui/Button.vue';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import PassportAccreditations from '@/modules/trades-passport-children/components/PassportAccreditations.vue';
import PassportAssociatedCompanies from '@/modules/trades-passport-children/components/PassportAssociatedCompanies.vue';
import PassportCompletedProjects from '@/modules/trades-passport-children/components/PassportCompletedProjects.vue';
import PassportSpecialismCard from '@/modules/trades-passport-children/components/PassportSpecialismCard.vue';
import PassportValueCard from '@/modules/trades-passport-children/components/PassportValueCard.vue';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';

export default defineComponent({
  name: 'TradesPassportOverview',

  components: {
    WelcomeBanner,
    PassportValueCard,
    PassportSpecialismCard,
    PassportAssociatedCompanies,
    PassportCompletedProjects,
    PassportAccreditations,
    Button
  },

  computed: {
    ...mapGetters(USER_WORKSTATION, ['getTypeWorkstation', 'activeUserWorkstation']),

    userProfileState() {
      return this.activeUserWorkstation?.verificationStatus;
    },

    isVerified() {
      return this.userProfileState === UserVerificationStatusEnum.VERIFIED;
    },
  },

  methods: {
    ...mapActions(['setWorkstationSetupModal']),

    onHandlePrimaryButtonClick() {
      this.setWorkstationSetupModal(true);
    },
  },
});
</script>

<style lang="scss" scoped>
.passport-overview {
  &__content {
  }

  &__content.col-3 {
    grid-template-columns: 1fr 2fr 1fr;
  }

  &__content.col-2 {
    grid-template-columns: 1fr 1fr;
  }

  &__button {
    border: 1px solid #4F55F0;
  }
}
</style>
